import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Scrollbars } from "react-custom-scrollbars-2"
import { Link, NavLink, matchPath } from "react-router-dom"
import { type, isNil } from "ramda"

import * as RouterUtils from "lib/core/router.utils"
import { isLeeruniekEmployee } from "lib/core/utils"

import { t } from "components/sidemenu/sidemenu.i18n"
import css from "components/sidemenu/sidemenu.module.scss"

export class LUSidemenu extends React.PureComponent {
  static propTypes = {
    logoURL: PropTypes.string.isRequired,
    currentURL: PropTypes.string.isRequired,
    schoolId: PropTypes.number.isRequired,
    schoolName: PropTypes.string,
    yearClassId: PropTypes.number,
    yearClassGrade: PropTypes.number,
    hasMultipleSchools: PropTypes.bool.isRequired,
    isSchoolSuperuser: PropTypes.bool,
    isGoalsVisible: PropTypes.bool,
  }

  static defaultProps = {
    yearClassId: null,
    yearClassGrade: null,
    isSchoolSuperuser: true,
    isGoalsVisible: false,
  }

  /**
   * When called, it should examine this.props and this.state and return a
   * single React element. This element can be either a representation of a
   * native DOM component, such as <div />, or another composite component
   * that you've defined yourself.
   *
   * @return {Component}
   */
  render() {
    const { schoolName, logoURL, hasMultipleSchools } = this.props
    const links = this.getMenuLinks()

    return (
      <nav className={css.sidemenu} role="navigation">
        <ul className={css.menu}>
          <li className={cx(css["menu-item"], css["menu-header"])}>
            <Link to={logoURL} className={cx(css["menu-link"])}>
              <span className={css["menu-header__logo"]} />
            </Link>
          </li>

          <Scrollbars
            autoHeight={true}
            autoHeightMax="calc(100vh - 138px)"
            renderThumbVertical={this.renderScrollbarThumb}>
            <li className={cx(css["menu-item"], css["school-switch"])}>
              {hasMultipleSchools ? (
                <NavLink
                  className={css["menu-link"]}
                  exact={true}
                  to={RouterUtils.get("schoolsList")}
                  isActive={this.isActive(["schoolsList"])}
                  activeClassName={css.active}>
                  <i className={cx(css["menu-icon"], "fa", "fa-university")} />
                  <span className={cx(css["menu-text"])}>{schoolName}</span>
                  <i
                    className={cx(
                      css["icon-exchange"],
                      "fa",
                      "fa-exchange-alt",
                    )}
                  />
                </NavLink>
              ) : (
                <a className={css["menu-link"]}>
                  <i className={cx(css["menu-icon"], "fa", "fa-university")} />
                  <span className={cx(css["menu-text"])}>{schoolName}</span>
                </a>
              )}
            </li>
            {this.renderLinks(links)}
          </Scrollbars>
        </ul>

        <div className={css.sidemenu__footer}>
          {`Leeruniek © ${new Date().getFullYear()} · `}
          <a
            href="https://leeruniek.nl"
            target="_blank"
            rel="noopener noreferrer">
            leeruniek.nl
          </a>
        </div>
      </nav>
    )
  }

  renderScrollbarThumb = (props) => (
    <div {...props} className={css["menu-scollbar__thumb"]} />
  )

  /**
   * { function_description }
   *
   * @param  {Array}  links  The links
   *
   * @return {Component[]}
   */
  renderLinks = (links) =>
    links.map((link) => {
      return Array.isArray(link) ? (
        <li key={link[0].id}>
          <ul className={css.submenu}>{this.renderLinks(link)}</ul>
        </li>
      ) : (
        this.renderLink(link)
      )
    })

  /**
   * Render individual link with submenu if `subMenuLinks` is provided
   *
   * @param  {Object}     link  The link
   *
   * @return {Component}  { description_of_the_return_value }
   */
  renderLink = (link) => {
    const { schoolId, currentURL } = this.props
    const isVisible =
      type(link.isVisible) === "Function"
        ? link.isVisible(null, { pathname: currentURL })
        : link.isVisible

    return isVisible ? (
      <li
        key={link.id}
        className={cx(css["menu-item"], link.className)}
        data-testid={link.label}>
        <NavLink
          className={cx(css["menu-link"])}
          isActive={link.isActive}
          activeClassName={css.active}
          to={RouterUtils.build(link.route, {
            schoolId,
            ...link.params,
          })}>
          <i className={cx(css["menu-icon"], link.icon)} />
          <span className={cx(css["menu-text"])}>{t(link.label)}</span>
        </NavLink>
      </li>
    ) : null
  }

  /**
   * Determines if current url matches any one of the routes
   *
   * @param  {string[]}  routeNames         List of router names
   * @param  {object}    match              Router match object
   * @param  {object}    location           Router location object
   * @param  {string}    location.pathname  Current url
   *
   * @return {Function}  Function compatible with ReactRouter's NavLink
   */

  isActive =
    (routeNames) =>
    (match, { pathname }) =>
      routeNames.some((routeName) =>
        matchPath(pathname, {
          exact: true,
          path: RouterUtils.get(routeName),
        }),
      )

  /**
   * Gets the menu links.
   *
   * @return {Array}  The menu links.
   */
  getMenuLinks = () => {
    const {
      yearClassId,
      yearClassGrade,
      isSchoolSuperuser,
      isGoalsVisible,
      user,
    } = this.props

    return [
      {
        route: "school",

        id: "school-nav-link",
        label: "sidemenu__school",
        icon: "fa fa-graduation-cap",
        isVisible: true,
        isActive: this.isActive([
          "school",
          "schoolSubject",
          "schoolSubjectDetails",
        ]),
      },
      {
        route: "years",

        id: "years-nav-link",
        label: "sidemenu__grades",
        icon: "far fa-calendar-check",
        isVisible: true,
        isActive: this.isActive(["years", "yearDetails"]),
      },
      {
        route: "groups",

        id: "groups-nav-link",
        label: "sidemenu__groups",
        icon: "fa fa-users",
        isVisible: true,
        isActive: this.isActive([
          "groups",
          "groupWorksheet",
          "groupResults",
          "groupGoals",
          "groupEmotional",
          "groupNotes",
        ]),
      },
      [
        {
          route: "groupWorksheet",
          params: {
            yearclassId: yearClassId,
          },

          id: "group-worksheet-nav-link",
          label: "sidemenu__groups--worksheet",
          className: css["submenu-item"],
          isVisible: !isNil(yearClassId),
          isActive: this.isActive(["groupWorksheet"]),
        },
        {
          route: "groupResults",
          params: {
            yearclassId: yearClassId,
          },

          id: "group-results-nav-link",
          label: "sidemenu__groups--results",
          className: css["submenu-item"],
          isVisible: !isNil(yearClassId) && yearClassGrade >= 3,
          isActive: this.isActive(["groupResults"]),
        },
        {
          route: "groupNotes",
          params: {
            yearclassId: yearClassId,
          },
          id: "group-notes-nav-link",
          label: "sidemenu__groups--notes",
          className: cx(css["submenu-item"]),
          isVisible: !isNil(yearClassId),
          isActive: this.isActive(["groupNotes"]),
        },
        {
          route: "groupGoals",
          params: {
            yearclassId: yearClassId,
          },

          id: "group-goals-nav-link",
          label: "sidemenu__groups--goals",
          className: cx(css["submenu-item"], css["submenu-item-beta"]),
          isVisible:
            !isNil(yearClassId) && yearClassGrade >= 3 && isGoalsVisible,
          isActive: this.isActive(["groupGoals"]),
        },
      ],
      {
        route: "pupils",
        id: "pupils-nav-link",
        label: "sidemenu__pupils",
        icon: "fa fa-user",
        isVisible: true,
        isActive: this.isActive([
          "pupils",
          "pupilDetails",
          "pupilSocialEmotional",
        ]),
      },
      {
        route: "admin__permissions",
        id: "admin--nav-link",
        label: "sidemenu__admin",
        icon: "fa fa-cogs",
        isVisible: isSchoolSuperuser,
        isActive: this.isActive([
          "admin__templates",
          "admin__permissions",
          "admin__pupils",
          "admin__upload",
        ]),
      },
      [
        {
          route: "admin__permissions",
          id: "admin__permissions--nav-link",
          label: "sidemenu__admin--permissions",
          className: css["submenu-item"],
          isVisible:
            isSchoolSuperuser &&
            this.isActive([
              "admin__templates",
              "admin__permissions",
              "admin__pupils",
              "admin__upload",
            ]),
          isActive: this.isActive(["admin__permissions"]),
        },
        {
          route: "admin__templates",
          id: "admin__templates--nav-link",
          label: "sidemenu__admin--templates",
          className: css["submenu-item"],
          isVisible:
            isSchoolSuperuser &&
            this.isActive([
              "admin__templates",
              "admin__permissions",
              "admin__pupils",
              "admin__upload",
            ]),
          isActive: this.isActive(["admin__templates"]),
        },
        {
          route: "admin__pupils",
          id: "admin__pupils--nav-link",
          label: "sidemenu__admin--pupils",
          className: css["submenu-item"],
          isVisible:
            isSchoolSuperuser &&
            this.isActive([
              "admin__templates",
              "admin__permissions",
              "admin__pupils",
              "admin__upload",
            ]),
          isActive: this.isActive(["admin__pupils"]),
        },
        {
          route: "admin__upload",
          id: "admin__upload--nav-link",
          label: "sidemenu__admin--upload",
          className: css["submenu-item"],
          //TODO: set isVisible condition after releasing this feature
          isVisible:
            isLeeruniekEmployee(user.email) &&
            this.isActive([
              "admin__templates",
              "admin__permissions",
              "admin__pupils",
              "admin__upload",
            ]),
          isActive: this.isActive(["admin__upload"]),
        },
      ],
    ]
  }
}
