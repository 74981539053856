export interface Note {
  id: number
  content: string
  dateCreated: string
  dateChanged: string
  userChanged: number
  userCreated: number
  userCreatedName: string
  userName: string
}

export interface FullNote extends Note {
  noteType: string
  instanceData: any
}

export interface ScoreAverageInterval {
  min: number
  avg: number
  max: number
}

export type Size = "small" | "large"

export enum ScoreType {
  LEVEL_VALUE = "level_value",
  PERCENTILE_SCORE = "percentile_score",
}

export interface TestProvider {
  id: number
  name: string
}

export enum NotificationSeverity {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export enum LoadingStatus {
  IDLE = "idle",
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export interface Selection {
  label: string
  value: string
}

export type MethodToSubjectDict = Record<string, string>
