import { createReducer } from "@reduxjs/toolkit"

import { FullNote } from "lib/leeruniek/interfaces"
import { fetchNotes } from "entities/group__notes.page/group__notes.actions"
import { LoadingStatus } from "lib/leeruniek/interfaces"

type State = {
  notes: FullNote[]
  notesLoadingStatus: LoadingStatus
}

type GetNotesResponse = {
  payload: FullNote[]
}

const initialState: State = {
  notes: [],
  notesLoadingStatus: LoadingStatus.IDLE,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(
      fetchNotes.pending,
      (state: State): State => ({
        ...state,
        notesLoadingStatus: LoadingStatus.PENDING,
      }),
    )
    .addCase(
      fetchNotes.fulfilled,
      (state: State, action: GetNotesResponse): State => ({
        ...state,
        notesLoadingStatus: LoadingStatus.SUCCEEDED,
        notes: action.payload,
      }),
    )
    .addCase(
      fetchNotes.rejected,
      (state: State): State => ({
        ...state,
        notesLoadingStatus: LoadingStatus.FAILED,
      }),
    )
})
